
import mixins from 'vue-typed-mixins';
import { SelectOption } from '@/types';

export default mixins().extend({
  name: 'SupplierPortalSectionForm',

  props: {
    source: {
      type: Object as () => {
        permissions: SelectOption[];
        permissionsSelected: string[];
        mastersSelected: SelectOption[];
      },
      required: true,
    },
  },

  components: {
    SupplierPortalPermissionSectionForm:
      () => import('./supplier-portal-permission-section/SupplierPortalPermissionSectionForm.vue'),
    SupplierPortalLinkedMastersSectionForm:
      () => import('./supplier-portal-linked-masters-section/SupplierPortalLinkedMastersSectionForm.vue'),
  },

  computed: {
    permissionSource: {
      set(data: { options: SelectOption[]; values: string[] }) {
        this.source.permissionsSelected = data.values;
      },
      get(): { options: SelectOption[]; values: string[] } {
        return {
          options: this.source.permissions,
          values: this.source.permissionsSelected,
        };
      },
    },
  },

});
